import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/issueIndex";
export const _frontmatter = {
  "title": "Volume 4, Issue 3"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Published: December, 2020`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./president-letter/"
        }}>{`President's Letter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./editorial/"
        }}>{`Editorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./afpi-karnataka-activities-2020/"
        }}>{`AFPI Karnataka Activities - 2020`}</a></li>
      <li parentName="ul">{`Practice Reflection: `}<a parentName="li" {...{
          "href": "./lessons-from-home-based-care/"
        }}>{`Lessons from Home-Based Care for a Patient with Acute Febrile Illness during the Pandemic`}</a></li>
      <li parentName="ul">{`Practice Reflection: `}<a parentName="li" {...{
          "href": "./supervised-smoking/"
        }}>{`Supervised Smoking`}</a></li>
      <li parentName="ul">{`Practice Reflection: `}<a parentName="li" {...{
          "href": "./moments-in-family-medicine/"
        }}>{`Moments in Family Medicine`}</a></li>
      <li parentName="ul">{`Reflections: `}<a parentName="li" {...{
          "href": "./a-heros-life/"
        }}>{`A Hero's Life`}</a></li>
      <li parentName="ul">{`Reflections: `}<a parentName="li" {...{
          "href": "./reflections-on-a-book/"
        }}>{`Reflections on a book`}</a></li>
      <li parentName="ul">{`Case notes: `}<a parentName="li" {...{
          "href": "./varied-managements-of-pcod/"
        }}>{`PCOD and its varied management – case scenarios in general practice`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "./from-the-web/"
        }}>{`From the Web`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      